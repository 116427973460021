import React, { useState } from 'react';
import './Styles/App.css';
import Container from './components/Container';
import SeminarFile from './components/Seminar/SeminarFile';
import LoginPage from './Login';
import Login from "../src/Login";
import { BrowserRouter as Router, Link, Routes, Route, BrowserRouter } from "react-router-dom";

// import AddEditSubtenant from './components/Subtenant/AddEditSubtenant';
// import AddEditCoordinator from './components/Coordinator/AddEditCoordinator';
import 'antd/dist/antd.css';
import AddEditSpeakerModal from './components/AddEditSpeakerModal';
import FirmPartner from './components/FirmPartner/FirmPartner';

import Preview from './components/Seminar/Preview';
import PrivateRoute from './components/Routes/PrivateRoute';
import Congratulations from './components/Seminar/Congratulations';
import DashBoard from './components/DashBoard/DashBoard';
import Metadata from './components/Seminar/Metadata';
import Classroom from './components/Classroom/Classroom';
import Communication from './components/Communication/Communication';
import { Addcommunicationmodal } from './components/Communication/CommunicationModal/Addcommunicationmodal';
// import { Switch } from 'antd';
import ManageSubscription from './components/Subscription/ManageSubscription';
import AddRateCard from './components/Subscription/AddRateCard';
import Filecabinet from './components/FileCabinet/Filecabinet';
import Feedback from './components/Feedback/Feedback';
import ParentCabinet from './components/newFileCabinet/ParentCabinet';
import SubscriptionStructure from './components/Subscription/SubscriptionStructure';
import Academy from './components/Academy';
import Chat from './components/Chat/Chat';
import CustomeIdleTimer from './components/CustomeIdleTimer';

export const UserContext = React.createContext({
  loader: false,
  loggedInUserRoles:[]
});

function App() {

  const [loader, setLoader] = useState(false);
  const [loggedInUserRoles,setLoggedInUserRoles]=useState([])


  return (
    <div className='All-main-container'>
      <UserContext.Provider value={{ loader: loader, setLoader: setLoader,setLoggedInUserRoles:setLoggedInUserRoles,loggedInUserRoles:loggedInUserRoles }}>

        <div className="overlay overlay position-relative">
          {loader &&
            <div className="d-flex align-items-center justify-content-center position-absolute overLayLoader">
              <div className="spinner-border" role="status" aria-hidden="true"></div>
            </div>
          }
          {/* .................... Private routing .................................... */}

          <BrowserRouter>
            <Routes>

          <Route path="/" exact element={<LoginPage />} />
          <Route path="/SeminarFile" exact element={
            <PrivateRoute>
              <SeminarFile />
           </PrivateRoute>
           
          } />
           <Route path="/Classroom" exact element={
            <PrivateRoute>
              <Classroom />
           </PrivateRoute>
           
          } />
          <Route path="/DashBoard" exact element={
            <PrivateRoute>
          <DashBoard/>
          </PrivateRoute>
          } />
            <Route path="/FirmPartner" exact element={
            <PrivateRoute>
          <FirmPartner/>
          </PrivateRoute>
          } />
          <Route path="/communication" exact element={
            <PrivateRoute>
              <Communication />
              </PrivateRoute>
           
          } />
          <Route path="/ProductManagement" exact element={
            <PrivateRoute>
          <SubscriptionStructure/>
          </PrivateRoute>
          } />
           <Route path="/Feedback" exact element={
            <PrivateRoute>
          <Feedback/>
          </PrivateRoute>
           }/>
              <Route path="/Filecabinet" exact element={
                <PrivateRoute>
                  <Filecabinet />
                </PrivateRoute>
              } />
              <Route path="/Chat" exact element={
                <PrivateRoute>
                  <Chat />
                </PrivateRoute>
              } />
              <Route path="/newFilecabinet" exact element={
                <PrivateRoute>
                  <ParentCabinet belongsScreen='newCabinet' />
                </PrivateRoute>
              } />
              <Route path="/academyUsers" exact element={
                <PrivateRoute>
                  <Academy belongsScreen='academyUsers' />
                </PrivateRoute>
              } />
          </Routes>
          
         
          </BrowserRouter>

          {/* ......................................................................... */}


        </div>
      </UserContext.Provider>

    </div>
  );
}

export default App;