import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Typography, Button, message, Tooltip, Switch } from "antd";
import { useParams, Link } from "react-router-dom";
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import moment from "moment";
import { UserContext } from '../../App';
import ToggleButton from '../ToggleButton';
import '../ToggleButton.css';
import './PublishedSeminar.css'
import Modal from 'react-bootstrap/Modal';
import QRCode from "react-qr-code";
import config from '../../config.json'
import { isValidFuntion } from '../../control/Constant';
import { BASE_URL,EVENTFUNNELURL } from '../../network/url';


const { Title, Text } = Typography;
const PublishedSeminar = (props) => {
    const { setLoader } = useContext(UserContext)
    const [seminardata, setSeminardata] = useState([])
    const [toggledata, setToggledata] = useState()
    const [imageUrl, setImageUrl] = useState('');
    const [text, setText] = useState('');
    const [show, setShow] = useState(false);
    const [NewStatee, setNewStatee] = useState([])
    const [showLess, setShowLess] = useState(false)
    const [readMoreProfile, setReadMoreProfile] = useState(null)
   
    const handleClose = (val) => {

        setShow(false);
    }


    const handleShow = (val) => {
        setNewStatee(val)

        setShow(true);
    }



    const subtenantId = commonLib.getSessionStoarge('subtenantId')
    const showFullProfile = (items, index, speakers1) => {
        setReadMoreProfile(items?.speakerUserId)
        let setAtZero = [];
        let filterData = [];
        filterData = speakers1.splice(index, 1);
        setAtZero = speakers1.unshift(items);
        setShowLess(true)
    }

    useEffect(() => {

      
        generateQrCode();
        setText(`${EVENTFUNNELURL}/eventlisting/${subtenantId}`)

    }, [subtenantId])

    useEffect(() => {
        getSeminar();
    }, [props?.filteredSubtinentId])
    

    const getSeminar = () => {
        let userroleid = JSON.parse(sessionStorage.getItem("stateObj"))?.roleId;
        let subtenantid = commonLib.getSessionStoarge('subtenantId')
        let foradminrole = false;
        if (userroleid != "4") {
            foradminrole = true;
        }
        let subtinentId = isValidFuntion(props?.filteredSubtinentId) ? props?.filteredSubtinentId : subtenantid 
        setLoader(true)
        let promise = Services.getSeminarData1(foradminrole, subtinentId)
        promise.then((res) => {
            setSeminardata(res?.data?.data)
            setLoader(false)
        }).catch((err) => {
            setSeminardata([])
        }).finally(() => {
            setLoader(false)
        })
    }




    const copysubtenant = (e) => {
        konsole.log("eeeee", e)
        var text = `${EVENTFUNNELURL}/eventlisting/${e?.subtenantId}/${e?.seminarId}`
        navigator.clipboard.writeText(text).then(_ => {
            // konsole.log('copied successfully!')
            message.success('Copied successfully!')
        })



    }
    const copysubtenants = (e) => {
        konsole.log("eeeee", e)
        var text = `${BASE_URL}/api/2/User/LeadstackerRegisterUserAsync/${e?.seminarId}/${e?.subtenantId}?firstName={{contact.first_name}}&lastName={{contact.last_name}}&phone={{contact.phone}}&email={{contact.email}}&noOfAttendees={{contact.number_of_attendes}}&source={{contact.where_did_you_hear_about_this_event}}`
        navigator.clipboard.writeText(text).then(_ => {
            // konsole.log('copied successfully!')
            message.success('Copied successfully!')
        })



    }
    const filterSpeakerDescriptionLegth = (dataSpeakerProfile) => {
        return dataSpeakerProfile.substr(0, 100);
    };


    const toggledatafun = (e, seminarObj) => {
        konsole.log("eeeee", e, seminarObj)
        const loggedInUser = commonLib.getObjFromStorage('stateObj');
        setLoader(true);
        Services.upsertSeminarStaus(seminarObj.seminarId, e, loggedInUser.userId)
            .then(res => {
                konsole.log("change status", res);
                setLoader(false);
                getSeminar();
            })
            .catch(error => {
                konsole.log("error", error);
                setLoader(false);
            }).finally(() => {
                setLoader(false);
            })
    }


    const generateQrCode = async () => {
        try {
            const response = await QRCode.toDataURL(text);
            setImageUrl(response);

        } catch (error) {
            konsole.log(error);
        }
    }



   
    const sortedSeminar = () => {
        const seminarListFiltered = seminardata.filter((e) => {
            const d1 = moment().format("YYYY-MM-DD");
            const d2 = moment(e.seminarDate).format("YYYY-MM-DD");
    
            if (props.pastSeminar === "Present") {
                return d1 <= d2;
            } else {
                return d1 > d2;
            }
        });
    
        return seminarListFiltered.sort((a, b) => {
            const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";
            const dateA = moment(a.seminarDate + " " + a.seminarTime.split(".")[0], dateTimeFormat);
            const dateB = moment(b.seminarDate + " " + b.seminarTime.split(".")[0], dateTimeFormat);
    
            return props.pastSeminar === "Present" ? dateA - dateB : dateB - dateA;
        });
    };
    


    const searchfun = () => {
        const currValue = props?.searchseminar;
        const data1 = sortedSeminar();
        let filteredData = data1.filter((entry) => {
            return entry.seminarTopic.toLowerCase().includes(currValue.toLowerCase());
        });
    
        return filteredData;
    };

    const returnfunforQR = (subtenant) => {
        return subtenant
    }

    const EditDataSendToSeminar = (particularData,state) => {
        if(state === "currentFuture"){
            particularData[state] = true
        }
       
        props.setEditFuncData(particularData)
        props.setEditFuncData2(particularData)
        props.setChangeBorderToTopButtons(1)
        props.setPublishSeminar(true)
    }
    const urlFunc=()=>{
        return `${BASE_URL}/api/2/User/LeadstackerRegisterUserAsync/${""}/${""}?firstName={contact.first_name}}&lastName={{contact.last_name}}&phone={{contact.phone_dummy_3}}&email={{contact.email}}`
    }



    return (
        <>
            <div className='d-flex justify-content-between align-items-center px-1'>
                <Title level={2} className="Modal-Retire-Life-Tag mt-4" style={{ color: "#720C20", }}>{(props?.pastSeminar == "Present") ? "Upcoming" : "Past"} Seminars</Title>
            </div>

        
            <div className='' style={{paddingLeft: "5px",paddingRight: "10px",
            
            }} >

               {
                    seminardata.length > 0 ? (
                        searchfun().length > 0 ? (
                            searchfun().map((seminarObj, index) => {
                                return (
                                    <div style={{ borderBottom: "1px dotted black" }} key={index}>
        
                                        <div className='w-100'>
        
                                            <Row>
                                                <Col span={24} xs={24} sm={24} lg={24}>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex'>
                                                            <Title level={1} className="Modal-Retire-Life-Tag" style={{ color: "#B06A0A", marginBottom: 4 }}>{seminarObj.seminarTopic} </Title>
        
                                                            <Text className='fs-4 ms-2' style={{ color: "black" }}>
                                                                {
                                                                    seminarObj.subtenantId !== 26 &&
                                                                    <span classname="ms-2 " style={{ color: "black", fontSize: "15px" }}>({seminarObj.seminarType}, {seminarObj.isSeminarPaid == true ? 'Paid' : 'Free'}{seminarObj.isSeminarPaid == true ? " : " + "$" + seminarObj.seminarFee : ""})
        
                                                                        {
                                                                            (props?.pastSeminar == "Present") ?
                                                                            <>
        
                                                                                <Tooltip title="Edit">        
                                                                                    <img src="./editImage.png" className="ms-3" style={{ cursor: "pointer" }} onClick={() => EditDataSendToSeminar(seminarObj)} />
                                                                                </Tooltip>
                                                                                        <Tooltip title="Duplicate">
                                                                                            <img src="./replicateIcon.png" className="ms-3" style={{ cursor: "pointer", width: "7%" }} onClick={() => EditDataSendToSeminar(seminarObj,"currentFuture")} />
                                                                                        </Tooltip>
                                                                            </> 
                                                                            :
                                                                                    <>
                                                                                        <Tooltip title="Duplicate">
                                                                                        <img src="./replicateIcon.png" className="ms-3" style={{ cursor: "pointer",width:"7%" }} onClick={() => EditDataSendToSeminar(seminarObj)} />
                                                                                        </Tooltip>
                                                                                    </>
                                                                        }
        
                                                                    </span>
                                                                }
                                                            </Text>
        
        
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                           {(props?.pastSeminar == "Present") ? <Switch checkedChildren="Show" onChange={(e) => toggledatafun(e, seminarObj)} unCheckedChildren="Hide" defaultChecked={seminarObj.isActive} /> : ""}
        
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    seminarObj.subtenantId == 26 &&
                                                    <Col span={24} md={6} className='d-flex justify-content-center justify-content-lg-end my-2'>
                                                        <button type="button" className="btn p-0 px-md-2 btn-outline-dark register-btn " >Register Now</button>
                                                    </Col>
                                                }
                                            </Row>
        
                                            <Row >
                                                <Col span={24}>
                                                    <Title level={3} style={{ marginBottom: 4, color: "#707070" }}>By:
        
                                                        {
                                                            seminarObj.speakers.length > 0 && seminarObj.speakers.map((speaker, index) => {
                                                                const fName = (speaker.fName !== null && speaker.fName !== '') ? speaker.fName : '';
                                                                const mName = (speaker.mName !== null && speaker.mName !== '') ? speaker.mName : ' ';
                                                                const lName = (speaker.lName !== null && speaker.fName !== '') ? speaker.lName : '';
                                                                return <span style={{ color: "#720C20", fontSize: "24px" }}>
                                                                    {` ${fName + " " + mName + " " + lName} ${(seminarObj.speakers.length - 1 === index) ? '' : ', '}`}
                                                                </span>
                                                            })
                                                        }
                                                    </Title>
                                                </Col>
        
                                            </Row>
                                            <Row className='m-0'>
                                                <Col span={24}>
                                                    <Title level={4} style={{ color: "#707070" }}>On {commonLib.getFormattedDate(seminarObj.seminarDate)}, at {commonLib.getFormattedTime(seminarObj.seminarTime)}</Title>
                                                </Col>
                                            </Row>
                                            {
                                                seminarObj.seminarTypeId == 46 ?
                                                    <>
                                                        <Row className=''>
                                                            <Col span={24}>
                                                                {(seminarObj.seminarVenue !== null) ?
                                                                    <Title level={4} style={{ color: "#707070" }}>Venue : {seminarObj.seminarVenue}.</Title> : ""}
                                                            </Col>
                                                        </Row>
                                                        <Row className='m-0 mb-2'>
                                                            <Col span={24}>
                                                                {(seminarObj.venueAddressLine1 !== null) ?
                                                                    <Title level={4} style={{ color: "#707070" }}>Location : {seminarObj.venueAddressLine1}.</Title> : ""}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    : <></>
                                            }
                                            {
                                                (seminarObj?.subtenantId !== 26) ?
                                                    <>
                                                   
                                                        <Row>
                                                            <Col span={24} className="" >
                                                                <Title level={3} style={{ marginTop: 10, color: "#720c20", marginBottom: 0 }}>About Seminar</Title>
                                                            </Col>
                                                            <Col span={24} className="">
                                                                <Text level={1} className="fs-6">
        
                                                                    {seminarObj.description}
        
                                                                </Text>
        
                                                            </Col>
                                                            <Col span={24} className="mt-1">
                                                                <Text level={1} className="fs-6">
                                                                    <span className="fs-4  fw-bold seminartxt">{seminarObj.speakers.length > 1 ? "Speakers" : "Speaker"}</span>
                                                                </Text>
        
                                                            </Col>
                                                            <Col span={24} className="mb-2">
                                                                <div className={`${showLess == true ? "readMorewillgrid" : "speaker-grid"}`}>
                                                                    {seminarObj.speakers.length > 0 && seminarObj.speakers.map((items, ind) => {
                                                                        return (
                                                                            <div
                                                                                className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-smith" : "will-smith mb-2"}`}
                                                                            >
                                                                                <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "d-block" : ""}`}>
                                                                                    <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-imgDiv" : "img-div"}`}>
                                                                                        <img
                                                                                            src={
                                                                                                items?.speakerProfileUrl
                                                                                                    !== null &&
                                                                                                    items?.speakerProfileUrl
                                                                                                    !== undefined &&
                                                                                                    items?.speakerProfileUrl
                                                                                                    !== ""
                                                                                                    ? items?.speakerProfileUrl
        
                                                                                                    : "/images/ApeakerNoImage1.png"
                                                                                            }
                                                                                            alt=""
                                                                                            className="img-fluid rounded-circle"
                                                                                        />
                                                                                    </div>
                                                                                    <h3>{items?.fName}</h3>
                                                                                </div>
                                                                                <Col span={24} className="mb-4 mt-4">
                                                                                    <p className={showLess == true ? "ms-3 mt-" : ""}>
        
                                                                                        {((readMoreProfile == items?.speakerUserId) && showLess == true) ? items?.speakerProfile : `${filterSpeakerDescriptionLegth(
                                                                                            items?.speakerProfile
                                                                                        )}...`}
                                                                                        {(items?.speakerProfile.length > 100 && showLess !== true) ? (
                                                                                            <a onClick={() => showFullProfile(items, ind, seminarObj.speakers)}>Read More</a>
                                                                                        ) : ((readMoreProfile == items?.speakerUserId) && showLess == true) ?
                                                                                            <a onClick={() => setShowLess(false)} className="fw-bold text-decoration-none text-black"> Show Less</a> :
                                                                                            <a onClick={() => showFullProfile(items, ind, seminarObj.speakers)}>Read More</a>
                                                                                        }
                                                                                    </p>
                                                                                </Col>
        
                                                                            </div>
                                                                        )
                                                                    })
                                                                    }
                                                                </div>
                                                            </Col>
        
        
                                                            <Col span={24} className="mb-1 d-flex justify-content-between">
                                                            <h6 className="d-flex justify-content-between align-items-center" >Leadstacker URL</h6>
                                                                <h6 className="d-flex justify-content-between align-items-center"style={{marginRight:"5rem"}} >Event URL</h6>
                                                                <div>
        
                                                                    <div className='w-100 d-flex justify-content-end m-0 p-0' style={{ maxWidth: 35, width: "100%" }} id='qr-gen'>
                                                                 </div>
                                                                </div>
        
                                                            </Col>
                                                            <Col span={24} className="mb-1 d-flex justify-content-between">
                                                              
                                                            <div className="d-flex justify-content-between align-items-center" >
                                                                   
                                                                   <Text level={1} className="fs-6 border border-2 ps-3 pe-2 pt-1 pb-1">
                                                                   
        
                                                                       <span className='pe-2 border-end border-2'>{BASE_URL}/api/2/.....</span>
        
                                                                       <Tooltip title="Copy">
                                                                           <span className='ps-1'><img className='pb-1 ps-1' onClick={() => copysubtenants(seminarObj)}  style={{ cursor: 'pointer' }} src='/icons/icon-park-outline_copy-link.svg' width={25} />  </span>
                                                                       </Tooltip>
        
                                                                   </Text>
                                                               </div>
                                                                <div className="d-flex justify-content-between align-items-center" >
                                                                    <Text level={1} className="fs-6 border border-2 ps-3 pe-2 pt-1 pb-1">
        
                                                                        <span className='pe-2 border-end border-2'>{EVENTFUNNELURL}/eventlisting/{seminarObj.subtenantId}/{seminarObj?.seminarId} </span>
        
                                                                        <Tooltip title="Copy">
                                                                            <span className='ps-1'><img className='pb-1 ps-1' onClick={() => copysubtenant(seminarObj)} style={{ cursor: 'pointer' }} src='/icons/icon-park-outline_copy-link.svg' width={25} />  </span>
                                                                        </Tooltip>
        
                                                                    </Text>
                                                                </div>
                                                                
                                                                <div>
        
                                                                    <div className='w-100 d-flex justify-content-end m-0 p-0' style={{ maxWidth: 35, width: "100%" }} id='qr-gen'>
                                                                        <QRCode
        
                                                                            size={256}
                                                                            style={{ height: "auto", width: "100%", cursor: "pointer", }}
                                                                            value={`${EVENTFUNNELURL}/eventlisting/${seminarObj.subtenantId}/${seminarObj?.seminarId}`}
                                                                            viewBox={`0 0 256 256`}
                                                                            onClick={() => handleShow(`${EVENTFUNNELURL}/eventlisting/${seminarObj.subtenantId}/${seminarObj?.seminarId}`)}
        
                                                                        />
        
                                                                    </div>
        
        
        
        
                                                                    <Modal show={show} onHide={handleClose} >
                                                                        <Modal.Header closeButton>
                                                                        </Modal.Header>
                                                                        <Modal.Body>
                                                                            <div className='d-flex justify-content-center align-items-center' id='qr-gen'>
                                                                                <QRCode
        
                                                                                    size={256}
                                                                                    style={{ height: "auto", width: "100%", cursor: "pointer" }}
                                                                                    value={NewStatee}
                                                                                    viewBox={`0 0 256 256`}
        
                                                                                />
        
                                                                            </div>
                                                                        </Modal.Body>
        
                                                                    </Modal>
        
                                                                </div>
        
        
        
                                                            </Col>
        
        
                                                        </Row>
        
                                                    </>
                                                    :
                                                    <>
                                                        <Row>
                                                            <Col span={4} xs={24} sm={24} md={4} lg={4} xl={3} className="m-0 mb-2 me-sm-3">
                                                                <div className='eventListingImage'>
                                                                    <img src='/icons/rajivSpeakerImage.jpeg' />
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} md={19} className="p-0 m-0 me-1">
                                                                <Text level={2} className="fs-6 ">
                                                                    70% of Americans not being able to live out their lives at home should be as much of an outrage as racism, gender discrimination, child abuse, etc.  BUT IT IS NOT.<br />
                                                                    Seniors are not cute and cuddly.<br />
                                                                    This, as you know, has been my life’s MISSION. To change the way we think of, plan for, and navigate through retirement.  And I need your support and help to tear down the walls that keep sunlight out of lives of the retired community members.<br />
                                                                    I am inviting a handful of my over 7,000 clients to share what I have been able to accomplish, and what I feel is needed as the next step to finally break down the walls.  And ask for your time and help that will also allow you to leave a sound legacy to be proud of – changing lives of retirees.<br />
                                                                </Text>
                                                            </Col>
                                                        </Row>
        
                                                    </>
                                            }
                                        </div>
        
                                    </div>
                                       )
                            })
                        ) : (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                <h1>Seminar not available</h1>
                            </div>
                        )
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                            <h1>Seminar not available</h1>
                        </div>
                    )
                }


            </div>
        </>
    )
}
export default PublishedSeminar
